<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <mask id="mask0_52_7194" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
      <rect width="16" height="16" fill="currentColor"/>
    </mask>
    <g mask="url(#mask0_52_7194)">
      <path d="M7.08897 8.00008L10.6667 11.5779L9.57786 12.6667L4.91119 8.00008L9.57786 3.33341L10.6667 4.4223L7.08897 8.00008Z" fill="currentColor"/>
    </g>
  </svg>
</template>

<script setup
        lang="ts">

</script>

<style scoped>

</style>